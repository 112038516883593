import axios from '@/libs/api.request'

//医院列表
export const aGetHospitalList = (params) => {
    return axios.request({
      url: '/helpHosp/hospital',
      method: 'get',
      params
    })
}
//新建医院
export const aCreateHospital = (data) => {
  return axios.request({
    url: '/helpHosp/hospital',
    method: 'post',
    data
  })
}
//更新医院
export const aEditHospital = ({id,data}) => {
  return axios.request({
    url: `/helpHosp/hospital/${id}`,
    method: 'put',
    data
  })
}
//删除医院
export const aRemoveHospital = (id) => {
  return axios.request({
    url: `/helpHosp/hospital/${id}`,
    method: 'delete',
  })
}

//医院信息
export const aGetHospitalInfo = () => {
  return axios.request({
    url: `/hosp/info`,
    method: 'get',
  })
}
//修改医院信息
export const aEditHospitalInfo = (data) => {
  return axios.request({
    url: `/hosp/update`,
    method: 'put',
    data
  })
}



//获取 省市区 数据
export const aGetCitySourceData = () => {
  return axios.request({
    url: '/area/tree',
    method: 'get',
  })
}


//科室列表 树
export const aGetDepartmentListTree = (params) => {
  return axios.request({
    url: '/hosp/department/tree',
    method: 'get',
    params
  })
}

//科室列表
export const aGetDepartmentList = (params) => {
  return axios.request({
    url: '/hosp/department',
    method: 'get',
    params
  })
}

//新建科室
export const aAddDepartment = (data) => {
  return axios.request({
    url: '/hosp/department',
    method: 'post',
    data
  })
}

//编辑科室
export const aEditDepartment = ({id,data}) => {
  return axios.request({
    url: `/hosp/department/${id}`,
    method: 'put',
    data
  })
}

//删除科室
export const aRemoveDepartment = (id) => {
  return axios.request({
    url: `/hosp/department/${id}`,
    method: 'delete',
  })
}

//多选删除科室
export const aMultipleRemoveDepartment = (data) => {
  return axios.request({
    url: `/hosp/departments`,
    method: 'delete',
    data
  })
}

//病种列表 树
export const aGetDiseaseTypeListTree = (params) => {
  return axios.request({
    url: '/hosp/disease/tree',
    method: 'get',
    params
  })
}

//病种列表 
export const aGetDiseaseTypeList = () => {
  return axios.request({
    url: '/hosp/disease',
    method: 'get',
  })
}

//新建病种
export const aAddDiseaseType = (data) => {
  return axios.request({
    url: '/hosp/disease',
    method: 'post',
    data
  })
}

//编辑病种
export const aEditDiseaseType = ({id,data}) => {
  return axios.request({
    url: `/hosp/disease/${id}`,
    method: 'put',
    data
  })
}

//删除病种
export const aRemoveDiseaseType = (id) => {
  return axios.request({
    url: `/hosp/disease/${id}`,
    method: 'delete',
  })
}

//多选删除病种
export const aMultipleRemoveDiseaseType = (data) => {
  return axios.request({
    url: `/hosp/diseases`,
    method: 'delete',
    data
  })
}

//医生列表
export const aGetDoctorList = (params) => {
	console.log(params,'aGetDoctorList');
  return axios.request({
    url: `/hosp/doctor`,
    method: 'get',
    params
  })
}

//新建医生
export const aAddDoctor = (data) => {
  return axios.request({
    url: `/hosp/doctor`,
    method: 'post',
    data
  })
}
//编辑医生
export const aEditDoctor = ({id,data}) => {
  return axios.request({
    url: `/hosp/doctor/${id}`,
    method: 'put',
    data
  })
}

//删除医生
export const aRemoveDoctor = (id) => {
  return axios.request({
    url: `/hosp/doctor/${id}`,
    method: 'delete',
  })
}
