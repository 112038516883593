export default {
  Note: 'Note',
  Sure: 'Sure',
  '': '',
  'Sign in': 'Sign in',
  'Phone Login': 'Phone Login',
  'Email Login': 'Email Login',
  'I have read and agree to the': 'I have read and agree to the',
  'Privacy Policy': 'Privacy Policy',
  and: 'and',
  'Terms of Use': 'Terms of Use',
  Login: 'Login',
  'Mobile number': 'Mobile number',
  'Verification code': 'Verification code',
  'Email address': 'Email address',
  'Please enter a valid phone number!': 'Please enter a valid phone number!',
  'Please enter a valid email address!': 'Please enter a valid email address!',
  'Please enter your phone number!': 'Please enter your phone number!',
  'Please enter your email address!': 'Please enter your email address!',
  'Please enter the verification code!': 'Please enter the verification code!',
  'Please accept the privacy policy and user agreement!': 'Please accept the privacy policy and user agreement!',
  'Incorrect verification code, please check the code!': 'Incorrect verification code, please check the code!',
  'The verification code has expired, please retrieve a new one!': 'The verification code has expired, please retrieve a new one!',
  'The verification code has been used, please retrieve a new one!': 'The verification code has been used, please retrieve a new one!',
  'Login failed, please try again.': 'Login failed, please try again.',
  SEND: 'SEND',
  ' ': ' ',
  'Currently in a meeting, please exit the current meeting before proceeding.': 'Currently in a meeting, please exit the current meeting before proceeding.',
  'Failed to initiate meeting': 'Failed to initiate meeting',
  'Failed to enter the meeting': 'Failed to enter the meeting',
  'Failed to enter the room.': 'Failed to enter the room.',
  'This action causes the room to be disbanded, does it continue?': 'This action causes the room to be disbanded, does it continue?',
  'This action causes the room to be exited, does it continue?': 'This action causes the room to be exited, does it continue?',
  'Quick Conference': ' \'s Quick Conference',
};
