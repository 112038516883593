<template>
  <div class="MTopBar">
    <Row type="flex" justify="space-between" align="middle" class="code-row-bg">
      <Col class="title">会议会诊系统</Col>
      <Col class="right u-flex">
        <div @click="editUser">
          <Avatar
            :src="bUrl.replace(/\/api/g, '/') + userInfo.avatar"
            style="background-color: #87d068"
            icon="ios-person"
          />
          {{ userInfo?.name ?? "用户名" }}
        </div>
        <div @click="logOut">
          <Avatar :src="logOutImg" />
          {{ "退出登录" }}
        </div>
      </Col>
    </Row>

    <Drawer
      title="个人信息"
      v-model="drawerData.isShow"
      width="500"
      :mask-closable="true"
      :styles="styles"
    >
      <Form ref="drawerForm" :model="drawerData.form" :label-width="80">
        <FormItem label="用户名" prop="name">
          <Input
            v-model="drawerData.form.name"
            placeholder="请输入用户名"
          ></Input>
        </FormItem>
        <FormItem label="头像">
          <el-upload
            :multiple="false"
            :limit="1"
            :file-list="imgList"
            :action="action"
            :headers="headers"
            list-type="picture-card"
            :on-success="handleSuccessUploadImg"
            :on-remove="
              (file, fileList) => {
                imgList = [];
              }
            "
            accept=".jpeg,.jpg,.gif,.png"
          >
            <Icon type="ios-cloud-upload-outline" />
          </el-upload>
        </FormItem>
        <FormItem label="账号" prop="email">
          <Input v-model="drawerData.form.email" disabled></Input>
        </FormItem>
        <FormItem label="原密码" prop="old_password">
          <Input
            v-model="drawerData.form.old_password"
            type="password"
            placeholder="请输入原密码"
          ></Input>
        </FormItem>
        <FormItem label="新密码" prop="password">
          <Input
            v-model="drawerData.form.password"
            type="password"
            placeholder="请输入新密码"
          ></Input>
        </FormItem>
        <!--   <FormItem label="联系方式" prop="phone">
          <Input
            v-model="drawerData.form.phone"
            placeholder="请输入联系方式"
          ></Input>
        </FormItem>
        <FormItem label="性别" prop="gender">
          <RadioGroup v-model="drawerData.form.gender">
            <Radio label="1">男</Radio>
            <Radio label="0">女</Radio>
          </RadioGroup>
        </FormItem> -->
      </Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="drawerData.isShow = false"
          >取消</Button
        >
        <Button type="primary" @click="modalSubmit">确定</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import config from "../../../../config1";
import { getToken } from "@/libs/util";
import { aEditUserInfo } from "../../../../api/user.js";
const baseUrl =
  process.env.NODE_ENV === "development"
    ? config.baseUrl.dev
    : config.baseUrl.pro;
export default {
  name: "MTopBar",
  props: {},
  components: {},
  data() {
    return {
      logOutImg: require("../../../../assets/images/log-out.png"),
      bUrl: baseUrl,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      action: baseUrl + "/upload",
      drawerData: {
        isShow: false,
        form: {
          name: "",
          // phone: "",
          // gender: "1",
          password: "",
          email: "",
          old_password: "",
        },
      },

      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      imgList: [],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user?.userInfo ?? {};
    },
  },
  watch: {},
  methods: {
    ...mapActions(["handleLogOut"]),
    logOut() {
      this.handleLogOut().then(() => {
        this.$router.push({
          name: "login",
        });
      });
    },
    editUser() {
      const { name, /* phone, gender, */ avatar, email } = this.userInfo ?? {};
      this.imgList = avatar
        ? [{ url: baseUrl.replace(/\/api/g, "/") + avatar, path: avatar }]
        : [];
      this.drawerData = {
        isShow: true,
        form: {
          name,
          password: "",
          /*  phone,
          gender: String(gender), */
          email,
          old_password: "",
        },
      };
    },
    modalSubmit() {
      this.$Modal.confirm({
        title: "确定要提交吗",
        content: `个人信息修改后，将会退出系统重新登录，请明确后修改！`,
        loading: true,
        onOk: () => {
          console.log(this.drawerData.form, this.imgList, "xxxxx");
          console.log("确定");
          const { name, password, old_password } = this.drawerData.form;
          if (!name) {
            this.$Message.error("请输入用户名");
            this.$Modal.remove();
          }
          const params = {
            name,
            avatar: this.imgList[0]?.path ?? "",
          };
          if (password.trim()) {
            Object.assign(params, {
              password,
              old_password,
            });
          }
          aEditUserInfo(params).then((res) => {
            console.log(res, "修改");
            if(res.data?.code !== 200) return this.$Modal.remove();
            this.$Message.success('修改成功')
            setTimeout(() => {
              this.$Modal.remove();
              this.logOut()
            }, 1000);
          });
        },
      });
    },
    handleSuccessUploadImg(response, file, fileList) {
      if (response.code != 200) {
        this.imgList = [];
        this.$Message.error("上传失败");
        return;
      }
      const url = response.data.url;
      const path = response.data.path;
      this.imgList = [{ url, path }];
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.MTopBar {
  padding-top: 20px;
}
.title {
  font-weight: bold;
  font-size: 36px;
  color: #333333;
}
.right {
  & > div {
    cursor: pointer;
    margin-right: 36px;
    font-size: 16px;
    color: #333333;
    display: flex;
    align-items: center;
    .ivu-avatar {
      margin-right: 16px;
    }
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>
