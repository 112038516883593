import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia, PiniaVuePlugin } from 'pinia';
import i18n from './locales';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import config from '@/config1'
import importDirective from '@/directive'
import '@/assets/icons/iconfont.css'
import './index.less'
import { directive as clickOutside } from 'v-click-outside-x'
import store from './store'
import './plugins/element.js'
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import variablePie from 'highcharts/modules/variable-pie';
import more from 'highcharts/highcharts-more';

variablePie(Highcharts);
more(Highcharts);
var _ = require("lodash");
window._L= _;
window._isShowSpin = (status:boolean,_this:any) => {
  if (status) {
    _this.$Spin.show({
      render: (h: any) => {
        return h("div", [
          h("Icon", {
            class: "demo-spin-icon-load",
            props: {
              type: "ios-loading",
              size: 18,
            },
          }),
          h("div", "Loading"),
        ]);
      },
    });
  } else {
    _this.$Spin.hide();
  }
},
process.env.NODE_ENV === "production" && (console.log = () => {})
Vue.prototype.$mBus = new Vue()

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(Viewer,{
 defaultOptions: {
 zIndex: 9999
 }
});
Vue.use(i18n);
Vue.use(ViewUI);
Vue.config.productionTip = false;
Vue.prototype.$config = config
importDirective(Vue)
Vue.directive('clickOutside', clickOutside)
Vue.prototype.hasRule = (access:any) => {
  return new Promise((resolve, reject) => {
    try {
      store.dispatch('getUserInfo').then(user => {
        resolve(user.access && user.access.includes('admin/' + access))
      })
    } catch (error) {
      reject(error)
    }
  })
}
Vue.use(VueHighcharts);

new Vue({
  pinia,
  store,
  // @ts-ignore
  router,
  render: h => h(App),
}).$mount('#app');
