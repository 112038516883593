import {
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  getMenuByRouter,
  getTagNavListFromLocalstorage,
  getHomeRoute,
  getNextRoute,
  routeHasExist,
  routeEqual,
  getRouteTitleHandled,
  localSave,
  localRead
} from '@/libs/util'
import router from '@/router'
import routes from '@/router/routers'

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter(item => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}

export default {
  state: {
    breadCrumbList: [],
    tagNavList: [],
    menuList: [],
    homeRoute: {},
    local: localRead('local'),
    errorList: [],
    hasReadErrorPage: false
  },
  getters: {
    menuList: (state, getters, rootState) => {
      if (state.menuList.length > 0) {
        return getMenuByRouter(state.menuList, rootState.user.userInfo.access)
      } else {
        return getMenuByRouter(routes, rootState.user.userInfo.access)
      }
    },
    errorCount: state => state.errorList.length
  },
  mutations: {
    setBreadCrumb (state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    updateMenuList (state, r) {
      console.log(r,'rrrrr');
      router.addRoutes(r)
      state.menuList = [
        {
          path: '/',
          name: '_home',
          redirect: '/home',
          meta: {
            title: '首页',
            hideInMenu: false,
            notCache: true,
            icon:'ios-home-outline'
          },
          children: [
            {
              path: '/home',
              name: 'home',
              meta: {
                hideInMenu: false,
                title: '首页',
                notCache: true,
                icon: 'ios-home-outline'
              },
              component: () => import('@/view/single-page/home')
            },
          ]
        },
        ...r
      ]
    },
    setHomeRoute (state, routes) {
      state.homeRoute = getHomeRoute(routes, 'home')
    },
    setTagNavList (state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else tagList = getTagNavListFromLocalstorage() || []
      if (tagList[0] && tagList[0].name !== 'home') tagList.shift()
      let homeTagIndex = tagList.findIndex(item => item.name === 'home')
      if (homeTagIndex > 0) {
        let homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
      state.tagNavList = tagList
      setTagNavListInLocalstorage([...tagList])
    },
    closeTag (state, route) {
      let tag = state.tagNavList.filter(item => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route)
    },
    addTag (state, { route, type = 'unshift' }) {
      let router = getRouteTitleHandled(route)
      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (router.name === 'home') state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }
        setTagNavListInLocalstorage([...state.tagNavList])
      }
    },
    setLocal (state, lang) {
      localSave('local', lang)
      state.local = lang
    },
    addError (state, error) {
      state.errorList.push(error)
    }
  }
}
