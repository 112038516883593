<template>
  <div style="height: 100%" class="main">
    <Layout style="height: 100%">
    <Sider hide-trigger collapsible :width="332" :collapsed-width="64" v-model="collapsed" class="left-sider" :style="{overflow: 'hidden'}">
      <side-menu ref="sideMenu" :active-name="$route.name" :collapsed="collapsed" @on-select="turnToPage" :menu-list="menuList">
        <!-- 需要放在菜单上面的内容，如Logo，写在side-menu标签内部，如下 -->
         <!-- <div style="margin: 36px 0;height: 58px; width: 214px;">
          <el-image
            style="width: 100%; height: 100%"
            :src="logo"
          />
         </div> -->
        <!-- <div class="logo-con" style="margin-bottom: 60px;">
          <img v-show="!collapsed" :src="maxLogo" key="max-logo" />
          <img v-show="collapsed" :src="minLogo" key="min-logo" />
        </div> -->
        <!-- <div class="hospitalLogo" :style="{background:`url(${hospitalLogo}) no-repeat 0 0 / 100% 100%`}" /> -->
        <div class="hospitalLogo"  >
			<img :src="hospitalLogo"/>
		</div>
      </side-menu>
      <div class="news" v-if="false">
        <div>
          <Icon type="ios-notifications" />
          <div></div>
        </div>
        <div>消息通知</div>
      </div>
    </Sider>
    <Layout>
      <Header class="header-con" style="height: auto !important;">
        <MTopBar />
        <header-bar :collapsed="collapsed" @on-coll-change="handleCollapsedChange" v-if="false">
          <user :user-avator="userAvatar"/>
          <language v-if="$config.useI18n" @on-lang-change="setLocal" style="margin-right: 10px;" :lang="local"/>
          <fullscreen v-model="isFullscreen" style="margin-right: 10px;"/>
        </header-bar>
      </Header>
      <Content class="main-content-con">
        <Layout class="main-layout-con">
          <div class="tag-nav-wrapper" v-if="false">
            <tags-nav :value="$route" @input="handleClick" :list="tagNavList" @on-close="handleCloseTag"/>
          </div>
          <Content class="content-wrapper">
            <keep-alive :include="cacheList">
              <router-view />
            </keep-alive>
            <ABackTop :height="100" :bottom="80" :right="50" container=".content-wrapper"></ABackTop>
          </Content>
        </Layout>
      </Content>
    </Layout>
    </Layout>
  </div>

</template>
<script>
import SideMenu from './components/side-menu'
import HeaderBar from './components/header-bar'
import TagsNav from './components/tags-nav'
import User from './components/user'
import ABackTop from './components/a-back-top'
import Fullscreen from './components/fullscreen'
import Language from './components/language'
import { mapMutations, mapActions } from 'vuex'
import { getNewTagList, routeEqual } from '@/libs/util'
import routers from '@/router/routers'
import minLogo from '@/assets/images/logo-min.jpg'
import maxLogo from '@/assets/images/logo.jpg'
import defaultImg from '@/assets/images/default-img.jpg'
import MTopBar from './components/MTopBar'
import './main.less'
import { aGetHospitalInfo } from '../../api/hospitalManagement'
import config from '../../config1'
const baseUrl =
  process.env.NODE_ENV === "development"
    ? config.baseUrl.dev
    : config.baseUrl.pro;
export default {
  name: 'Main',
  components: {
    SideMenu,
    HeaderBar,
    Language,
    TagsNav,
    Fullscreen,
    User,
    ABackTop,
    MTopBar,
  },
  data () {
    return {
      collapsed: false,
      minLogo,
      maxLogo,
      isFullscreen: false,
      logo:require('@/assets/images/new-logo.png'),
      hospitalLogo:'',
    }
  },
  computed: {
    tagNavList () {
      return this.$store.state.app.tagNavList
    },
    userAvatar () {
      if (JSON.stringify(this.$store.state.user.userInfo) !== '{}') {
        let userData = {}
        if (this.$store.state.user.userInfo.hasOwnProperty('user_data') && !this.$store.state.user.userInfo.hasOwnProperty('userData')) {
          userData = this.$store.state.user.userInfo.user_data
        } else {
          userData = this.$store.state.user.userInfo.userData
        }
        if (userData?.head_img) {
          return userData?.head_img
        }
      }
      return defaultImg
    },
    cacheList () {
      return ['ParentView', ...this.tagNavList.length ? this.tagNavList.filter(item => !(item.meta && item.meta.notCache)).map(item => item.name) : []]
    },
    menuList () {
      return this.$store.getters.menuList
    },
    local () {
      return this.$store.state.app.local
    }
  },
  methods: {
    ...mapMutations([
      'setBreadCrumb',
      'setTagNavList',
      'addTag',
      'setLocal',
      'setHomeRoute',
      'closeTag'
    ]),
    ...mapActions([
      'handleLogin'
    ]),
    turnToPage (route) {
      let { name, params, query } = {}
      if (typeof route === 'string') name = route
      else {
        name = route.name
        params = route.params
        query = route.query
      }
      if (name.indexOf('isTurnByHref_') > -1) {
        window.open(name.split('_')[1])
        return
      }
      //TODO 
      console.log(name,params,query,'跳转');
      this.$router.push({
        name,
        params,
        query
      })
    },
    handleCollapsedChange (state) {
      this.collapsed = state
    },
    handleCloseTag (res, type, route) {
      if (type !== 'others') {
        if (type === 'all') {
          this.turnToPage('home')
        } else {
          if (routeEqual(this.$route, route)) {
            this.closeTag(route)
          }
        }
      }
      this.setTagNavList(res)
    },
    handleClick (item) {
      this.turnToPage(item)
    }
  },
  watch: {
    '$route' (newRoute) {
      const { name, query, params, meta } = newRoute
      this.addTag({
        route: { name, query, params, meta },
        type: 'push'
      })
      this.setBreadCrumb(newRoute)
      this.setTagNavList(getNewTagList(this.tagNavList, newRoute))
      this.$refs.sideMenu.updateOpenName(newRoute.name)
    }
  },
  mounted () {
    /**
     * @description 初始化设置面包屑导航和标签导航
     */
    this.setTagNavList()
    this.setHomeRoute(routers)
    const { name, params, query, meta } = this.$route
    this.addTag({
      route: { name, params, query, meta }
    })
    this.setBreadCrumb(this.$route)
    // 设置初始语言
    this.setLocal(this.$i18n.locale)
    // 如果当前打开页面不在标签栏中，跳到homeName页
    if (!this.tagNavList.find(item => item.name === this.$route.name)) {
      this.$router.push({
        name: 'home'
      })
    }
    aGetHospitalInfo().then(res=>{
      if(res?.data?.code === 200){
        this.hospitalLogo =  res?.data?.data?.logo ? baseUrl.replace(/\/api/g, '/') + res?.data?.data?.logo : ''
      }
    })
  }
}
</script>

<style lang="less" scoped>
  .hospitalLogo{
    max-width: 214px;
	height: 100%;
    //border-radius: 30px ;
    overflow: hidden;
    margin: 34px auto;
	img{
		width: 100%;
		height: 100%;
	}
  }
</style>
