import Main from '@/components/main'
import { dynamicRouterAdd } from '@/libs/router-utils'

/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中，示例看QQ群路由配置
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面在切换标签后不会缓存，如果需要缓存，无需设置这个字段，而且需要设置页面组件name属性和路由配置的name一致
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 * }
 */

export const otherRouter = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      hideInMenu: true
    },
    component: () => import('@/view/login/login.vue')
  },
  {
    path: '/login/:loginType',
    name: 'login',
    meta: {
      title: '登录',
      hideInMenu: true
    },
    component: () => import('@/view/login/login.vue')
  },
  {
    path: '/',
    name: '_home',
    redirect: '/home',
    component: Main,
    meta: {
      title: '首页',
      hideInMenu: false,
      notCache: true,
      icon:'ios-home-outline'
    },
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          hideInMenu: false,
          title: '首页',
          notCache: true,
          icon: 'ios-home-outline'
        },
        component: () => import('@/view/single-page/home')
      },
      {
        path: '/user-center',
        name: 'user_center',
        meta: {
          hideInMenu: true,
          title: '个人中心',
          notCache: true,
          icon: 'md-home',
          access: 'admin/User/own'
        },
        component: () => import('@/view/single-page/own/own.vue')
      }
    ]
  },
  {
    path: '/401',
    name: 'error_401',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/401.vue')
  },
  {
    path: '/500',
    name: 'error_500',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/500.vue')
  },
  {
    path: '*',
    name: 'error_404',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/404.vue')
  },
  {
    path: '/wiki/list',
    name: 'wiki_list',
    meta: {
      title: '文档列表',
      hideInMenu: true
    },
    component: () => import('@/view/wiki/list.vue')
  },
  {
    path: '/wiki/error',
    name: 'wiki_error',
    meta: {
      title: '错误码',
      hideInMenu: true
    },
    component: () => import('@/view/wiki/error-code.vue')
  },
  {
    path: '/wiki/calculation',
    name: 'wiki_calculation',
    meta: {
      title: '算法详解',
      hideInMenu: true
    },
    component: () => import('@/view/wiki/calculation.vue')
  },
  {
    path: '/wiki/login',
    name: 'wiki_login',
    meta: {
      title: '文档登录',
      hideInMenu: true
    },
    component: () => import('@/view/wiki/login.vue')
  },
  // {
  //   path: '/consultationCenter',
  //   name: '_nConsultationCenter',
  //   component: Main,  //TODO 改成空字符串
  //   redirect: '/consultationCenter/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'会诊中心',
  //     icon:'ios-laptop'
  //   },
  //   children: [
  //     { 
  //       component: () => import('@/view/ConsultationCenter/index.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'会诊列表',
  //         icon:'md-list'
  //       },
  //       name:'nConsultationCenter',
  //       path: 'index',
  //     },
  //     { 
  //       component: () => import('@/view/ConsultationCenter/ConsultationTeam.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'会诊团队',
  //         icon:'md-cube'
  //       },
  //       name:'nConsultationTeam',
  //       path: 'consultationTeam',
  //     },
  //   ]
  // },
  // {
  //   path: '/HospitalInfo',
  //   name: '_nHospitalInfo',
  //   component: Main,  //TODO 改成空字符串
  //  // redirect: '/HospitalInfo/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'医院信息',
  //     icon:'md-card'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/HospitalManagement/HospitalInfo.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'医院信息',
  //         icon:'md-card'
  //       },
  //       name:'nHospitalInfo',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/HospitalList',
  //   name: '_nHospitalList',
  //   component: Main,  //TODO 改成空字符串
  //  // redirect: '/HospitalList/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'帮扶医院',
  //     icon:'ios-albums'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/HospitalManagement/HospitalList.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'帮扶医院',
  //         icon:'ios-list'
  //       },
  //       name:'nHospitalList',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/DepartmentManagement',
  //   name: '_nDepartmentManagement',
  //   component: Main,  //TODO 改成空字符串
  //   redirect: '/DepartmentManagement/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'科室管理',
  //     icon:'ios-list-box-outline'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/HospitalManagement/DepartmentManagement.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'科室管理',
  //         icon:'ios-list-box-outline'
  //       },
  //       name:'nDepartmentManagement',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/DiseaseDatabase',
  //   name: '_nDiseaseDatabase',
  //   component: Main,  //TODO 改成空字符串
  //  // redirect: '/DiseaseDatabase/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'病种库管理',
  //     icon:'ios-cog-outline'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/HospitalManagement/DiseaseDatabase.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'病种库管理',
  //         icon:'ios-cog-outline'
  //       },
  //       name:'nDiseaseDatabase',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/DoctorList',
  //   name: '_nDoctorList',
  //   component: Main,  //TODO 改成空字符串
  //   redirect: '/DoctorList/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'医生管理',
  //     icon:'md-people'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/HospitalManagement/DoctorList.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'医生管理',
  //         icon:'md-people'
  //       },
  //       name:'nDoctorList',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path:'/PatientManagement',
  //   name:'_nPatientManagement',
  //   component:Main,
  //   redirect:'/PatientManagement/index',
  //   meta:{
  //     hideInMenu:false,
  //     title:'患者管理',
  //     icon:'md-person'
  //   },
  //   children:[
  //     {
  //       component: () => import('@/view/PatientManagement/index.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'患者管理',
  //         icon:'md-person'
  //       },
  //       name:'nPatientManagement',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/hospitalManagement',
  //   name: 'hospitalManagement',
  //   component: Main,  //TODO 改成空字符串
  //   redirect: '/consultationCenter/hospitalList',
  //   meta: {
  //     hideInMenu: false,
  //     title:'医院管理',
  //     icon:'ios-albums'
  //   },
  //   children: [
  //     { 
  //       component: () => import('@/view/HospitalManagement/HospitalList.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'医院列表',
  //         icon:'ios-list'
  //       },
  //       name:'nHospitalList',
  //       path: 'HospitalList',
  //     },
  //     { 
  //       component: () => import('@/view/HospitalManagement/DepartmentManagement.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'科室管理',
  //         icon:'ios-list-box-outline'
  //       },
  //       name:'nDepartmentManagement',
  //       path: 'DepartmentManagement',
  //     },
  //     { 
  //       component: () => import('@/view/HospitalManagement/DiseaseDatabase.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'病种库',
  //         icon:'ios-cog-outline'
  //       },
  //       name:'nDiseaseDatabase',
  //       path: 'DiseaseDatabase',
  //     },
  //     { 
  //       component: () => import('@/view/HospitalManagement/DoctorList.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'医生列表',
  //         icon:'md-people'
  //       },
  //       name:'nDoctorList',
  //       path: 'DoctorList',
  //     },
  //   ]
  // },
  // {
  //   path: '/Notification',
  //   name: 'notification',
  //   component: Main,
  //   redirect: '/Notification/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'消息通知',
  //     icon:'logo-whatsapp'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/Notification/index.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'消息管理',
  //         icon:'logo-whatsapp'
  //       },
  //       name:'notification',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/UserList',
  //   name: '_nUserList',
  //   component: Main,  //TODO 改成空字符串
  //   redirect: '/UserList/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'用户管理',
  //     icon:'md-people'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/system/UserList.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'用户管理',
  //         icon:'md-people'
  //       },
  //       name:'nUserList',
  //       path: 'index',
  //     }
  //   ]
  // },
  // {
  //   path: '/Log',
  //   name: 'nLog',
  //   component: Main,
  //   redirect: '/Log/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'日志管理',
  //     icon:'logo-windows'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/Log/index.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'系统日志',
  //         icon:'logo-steam'
  //       },
  //       name:'log',
  //       path: 'index',
  //     },
  //     { 
  //       component: () => import('@/view/Log/request.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'腾讯日志',
  //         icon:'logo-tux'
  //       },
  //       name:'request',
  //       path: 'request',
  //     },
  //     { 
  //       component: () => import('@/view/Log/reply.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'回调日志',
  //         icon:'ios-archive'
  //       },
  //       name:'reply',
  //       path: 'reply',
  //     }
  //   ]
  // },
  { 
    component: () => import('@/view/Room/index.vue'),
    meta: {
      hideInMenu: true,
      title:'会议',
      icon:'ios-aperture-outline'
    },
    name:'nRoom',
    path: '/Room',
  },


  // {
  //   path: '/system',
  //   name: '系统管理',
  //   component: Main,  //TODO 改成空字符串
  // //redirect: '/consultationCenter/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'系统管理',
  //     icon:'md-menu'
  //   },
  //   children: [
  //     { 
  //       component: () => import('@/view/system/menu.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'菜单维护',
  //         icon:'ios-repeat'
  //       },
  //       name:'菜单维护',
  //       path: 'menu',
  //     },
  //     {
  //       component: () => import('@/view/system/Role.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'角色列表',
  //         icon:'ios-grid'
  //       },
  //       name:'角色列表',
  //       path: 'Role',
  //     },
  //   ]
  // },
  // {
  //   path: '/StatisticAnalysis',
  //   name: '_nStatisticAnalysis',
  //   component: Main,  //TODO 改成空字符串
  //  // redirect: '/HospitalInfo/index',
  //   meta: {
  //     hideInMenu: false,
  //     title:'统计分析',
  //     icon:'md-aperture'
  //   },
  //   children:[
  //     { 
  //       component: () => import('@/view/StatisticAnalysis/index.vue'),
  //       meta: {
  //         hideInMenu: false,
  //         title:'统计分析',
  //         icon:'md-aperture'
  //       },
  //       name:'nStatisticAnalysis',
  //       path: 'index',
  //     }
  //   ]
  // },
]

export const appRouter = [...dynamicRouterAdd()]

export const routes = [
  ...otherRouter,
  ...appRouter
]

// 所有上面定义的路由都要写在下面输出
export default routes
