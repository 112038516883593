import Vue from 'vue'         
                                                
import { Empty,Upload,Image,Tree,Loading,Dialog, Checkbox} from 'element-ui'

// Vue.prototype.$message = Message
// Vue.prototype.$confirm = MessageBox.confirm 


Vue.use(Empty)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Tree)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Checkbox)